import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
import React, { Suspense, lazy, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import classnames from "classnames"
import "../../../../src//styles/errorfield.scss"
import AssetImageTable from "components/DataTables/AssetImageTable"

import {
  Spinner,
  Container,
  Form,
  NavItem,
  NavLink,
  Button,
  TabContent,
  TabPane,
} from "reactstrap"
import { loginUserData, userId } from "helpers/userId"
import { FileUpload } from "helpers/file_uploads/upload_handler"
import { change } from "redux-form" // changes

const ListOfOwners = lazy(() =>
  import("components/Customers/Services/ListOfOwners")
)

const ListofBeneficiaries = lazy(() =>
  import("components/Customers/Services/ListofBeneficiaries")
)

const ListofDocuments = lazy(() =>
  import("components/Customers/Services/ListofDocuments")
)

const initialFormDetails = {
  pro_asset_name: "",
  pro_purchase_date: "",
  pro_property_type: "",
  pro_shared: "Family",
  pro_asset_value: "",
  pro_asset_value_currency: "",
  pro_upgraded_value: "",
  pro_upgraded_value_currency: "",
  pro_present_value: "",
  pro_present_value_currency: "",
  pro_county_asset: "",
  pro_type_data: "",
  pro_number_of_owners: "",
  Family: true,
  Friends: false,
  Others: false,
  Relative: false,

  // realestate
  pre_type_of_real_eatate: "",
  pre_number_of_yads: "",
  pre_registrar: "",
  pre_country: "",
  pre_str1: "",
  pre_str2: "",
  pre_city: "",
  pre_region: "",
  pre_zip: "",
  realEstateRegionList: [],

  // financier
  prof_financier_name: "",
  prof_loan_num: "",
  prof_loan_date: "",
  prof_address: "",
  prof_loan_amount: "",
  prof_no_of_year_loan: "",
  prof_apr: "",
  prof_financier_phone: "",
  prof_financier_website: "",

  user_fname: "",
  user_mname: "",
  user_lname: "",
  user_email: "",
  user_phone: "",
  user_address: "",
  is_address: "Yes",
  is_alive: "Yes",
  user_country: "",
  user_zip: "",
  user_birthdate: "",
  relationship: "",
  relationsList: [],
  regionsList: [],
  addres1: "",
  addres2: "",
  city: "",
  region: "",
  ac_image: "",

  pq_type: "No",

  pbl_name: "",
  pbl_relitionship: "",
  pbl_persant: "",

  pdl_document_name: "",
  pdl_tyep_document: "",
  pdl_image: "",

  listOfOwners: [],
  ListofBeneficiaries: [],
  ListofDocuments: [],

  listOfExistingOwners: [],
  ListOfExistingBeneficiary: [],
  ListofExistingDocuments: [],

  questions: [],
  imagesList: [],

  errors: {
    pro_asset_name: "",
    pro_property_type: "",
    pro_asset_value: "",
    pro_county_asset: "",
    pro_asset_value_currency: "",
  },
}

const AddAssetWallet = () => {
  const [activeTab, setactiveTab] = useState(
    (localStorage.getItem("tab") &&
      parseInt(JSON.parse(localStorage.getItem("tab")))) ||
      1
  )

  const [form, setForm] = useState(initialFormDetails)
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  const relationsList = JSON.parse(localStorage.getItem("relationsList"))

  const [errors, setErrors] = useState({})
  const [changed, setChanged] = useState(undefined) // changed

  // history for navigation
  const history = useNavigate()

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const type = searchParams.get("type")
  const assetId = parseInt(JSON.parse(localStorage.getItem("assetId")))

  async function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 7) {
        setactiveTab(tab)
        localStorage.setItem("tab", tab)

        if (activeTab == 5) {
          await fetchBeneficiaryData()
        }
        if (activeTab == 6) {
          await fetchListOfDocuments()
        }
      }
    }
  }

  const validateForm = () => {
    var isValid = true
    const newErrors = {}

    // Validation logic for each input
    if (!form.user_fname.trim()) {
      newErrors.user_fname = "First name is required"
      isValid = false
    }

    if (!form.user_lname.trim()) {
      newErrors.user_lname = "Last name is required"
      isValid = false
    }
    

    setErrors(newErrors)
    return isValid
  }

  // -- Fetch relationships list start -- //
  async function fetchRelationsList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list`
      )
      const data = await response.json()

      if (data?.data) {
        localStorage.setItem("relationsList", JSON.stringify(data?.data))
      }

      return data?.data
    } catch (error) {
      console.log(error)
    }
  }

  async function fetchRegionsList(countryName, type) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
      )

      const data = await response.json()
      if (type != 1) {
        setForm({
          ...form,
          realEstateRegionList: data?.data || [],
          pre_region: data.data[0]?.region || "",
          pre_country: countryName,
        })
      }

      return data?.data || []
    } catch (error) {
      console.log(error)
    }
  }

  async function fetchBeneficiaryData() {
    try {
      const url = `${
        process.env.REACT_APP_DOMAIN_URL
      }api/v1/customer/services/beneficiaries_list?pro_id=${
        id || assetId
      }&pbl_uid=${userId}`

      const response = await fetch(url)
      const data = await response.json()
      const decryptedData = data?.data?.map((each, idx) => {
        return {
          ...each,
          number: idx + 1,
          pbl_name: each.pbl_name || "",
          pbl_relitionship: each.pbl_relitionship || "",
          pbl_persant: each.pbl_persant || "",
          relationsList: relationsList || [],
        }
      })

      setForm({
        ...form,
        ListofBeneficiaries: decryptedData || [],
        listOfOwners: [],
      })
    } catch (err) {}
  }

  let timeoutId
  useEffect(() => {
    if (activeTab === 5) {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        fetchBeneficiaryData()
      }, 100) // Delay to debounce API calls
    }
    return () => clearTimeout(timeoutId) // Clean up on unmount
  }, [activeTab])

  const handleRemoveBeneficiary = async (pbl_id, idx) => {
    try {
      if (pbl_id) {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_beneficiaries`
        const data = {
          pbl_id: pbl_id,
        }
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})
        const removeData = await response.json()

        if (removeData?.success === true) {
          showMessage(removeData.message, "success")
          setForm({
            ...form,
            ListofBeneficiaries: form.ListofBeneficiaries.filter(
              each => each.pbl_id !== pbl_id
            ),
          })
        } else {
          showMessage(removeData.message, "error", false)
        }
      } else if (idx >= 0) {
        setForm({
          ...form,
          ListofBeneficiaries: form.ListofBeneficiaries.filter(
            (each, index) => index != idx
          ),
        })
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  async function fetchListOfDocuments() {
    try {
      const url = `${
        process.env.REACT_APP_DOMAIN_URL
      }api/v1/customer/services/document_list?pro_id=${
        id || assetId
      }&pdl_uid=${userId}`

      const response = await fetch(url)
      const data = await response.json()
      const decryptedData = data?.data
      setForm({
        ...form,
        ListofDocuments: decryptedData || [],
      })
    } catch (err) {}
  }

  useEffect(() => {
    if (activeTab === 6) {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        fetchListOfDocuments()
      }, 300) // Delay to debounce API calls
    }
    return () => clearTimeout(timeoutId) // Clean up on unmount
  }, [activeTab])

  const handleRemoveDocuments = async pdl_id => {
    try {
      if (pdl_id) {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/delete_document`
        const data = {
          pdl_id: pdl_id,
        }
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }

        const response = await fetch(url, options, {})
        const removeData = await response.json()

        if (removeData?.success === true) {
          showMessage(removeData.message, "success")
          setForm({
            ...form,
            ListofDocuments: form.ListofDocuments.filter(
              each => each.pdl_id !== pdl_id
            ),
          })
        } else {
          showMessage(removeData.message, "error", false)
        }
      } else if (pdl_id >= 0) {
        setForm({
          ...form,
          ListofDocuments: form.ListofDocuments.filter(
            (each, index) => each.pdl_id != idx
          ),
        })
      }
    } catch (error) {
      showMessage(removeData?.message || "Something went wrong", "error", false)
    }
  }

  {
    /* */
  }

  useEffect(() => {
    if (activeTab === 5) {
      setTimeout(() => {
        fetchBeneficiaryData()
      }, [activeTab])
    }

    if (activeTab === 6) {
      setTimeout(() => {
        fetchListOfDocuments()
      }, [activeTab])
    }
  }, [activeTab])

  useEffect(() => {
    fetchRelationsList()
  }, [activeTab])

  useEffect(() => {
    if (activeTab) {
      async function setFormData(data) {
        if (data.success) {
          const decryptedData = data?.data[0]
          if (decryptedData) {
            const sharedList = decryptedData?.pro_shared?.split(",") || []
            const assetInformation = {
              ...decryptedData,
              pro_key: decryptedData.pro_key,
              pro_purchase_date: decryptedData.pro_purchase_date,
              pro_property_type: decryptedData.pro_property_type,
              pro_asset_value: decryptedData.pro_asset_value,
              pro_asset_value_currency: decryptedData.pro_asset_value_currency,
              pro_present_value: decryptedData.pro_present_value,
              pro_present_value_currency:
                decryptedData.pro_present_value_currency,
              pro_number_of_owners: decryptedData.pro_number_of_owners,
              pro_county_asset: decryptedData.pro_county_asset,
              Family: sharedList.includes("Family"),
              Friends: sharedList.includes("Friends"),
              Others: sharedList.includes("Others"),
              Relative: sharedList.includes("Relative"),
              pro_upgraded_value: decryptedData.pro_upgraded_value,
              pro_upgraded_value_currency:
                decryptedData.pro_upgraded_value_currency,

              // realestate data
              pre_type_of_real_eatate: decryptedData.pre_type_of_real_eatate,
              pre_number_of_yads: decryptedData.pre_number_of_yads,
              pre_registrar: decryptedData.pre_registrar,
              pre_country: decryptedData.pre_country,
              pre_str1: decryptedData.pre_str1,
              pre_str2: decryptedData.pre_str2,
              pre_city: decryptedData.pre_city,
              pre_region: decryptedData.pre_region,
              pre_zip: decryptedData.pre_zip,

              // financier data
              prof_financier_name: decryptedData.prof_financier_name,
              prof_loan_num: decryptedData.prof_loan_num,
              prof_loan_date: decryptedData.prof_loan_date,
              prof_address: decryptedData.prof_address,
              prof_loan_amount: decryptedData.prof_loan_amount,
              prof_no_of_year_loan: decryptedData.prof_no_of_year_loan,
              prof_apr: decryptedData.prof_apr,
              prof_financier_phone: decryptedData.prof_financier_phone,
              prof_financier_website: decryptedData.prof_financier_website,
            }

            let questions = []

            if (activeTab) {
              const response = await fetch(
                `${
                  process.env.REACT_APP_DOMAIN_URL
                }api/v1/customer/services/get_questions_list?pro_uid=${userId}&pro_id=${
                  id || assetId
                }`
              )

              const data = await response.json()

              questions = data?.data || []
            }

            setForm({
              ...form,
              ...assetInformation,
              realEstateRegionList: await fetchRegionsList(
                assetInformation.pre_country,
                1
              ),
              questions: questions,
            })
          }
        }

        return true
      }
      if (assetId && !id) {
        try {
          const fetchData = async () => {
            const response = await fetch(
              `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_asset_info?user_id=${userId}&pro_id=${assetId}`
            )

            const data = await response.json()
            await setFormData(data)
          }

          fetchData()
        } catch (e) {}
      }

      if (id) {
        try {
          const fetchDataEditData = async () => {
            const response = await fetch(
              `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/get_asset_info?user_id=${userId}&pro_id=${id}`
            )

            const data = await response.json()
            setFormData(data)
          }

          fetchDataEditData()
        } catch (e) {}
      }
    }
  }, [activeTab])

  const handleForm = async (e, action, key) => {
    let { name, value, type, checked } = e?.target || {}

    switch (action) {
      case "assetInformation":
        setForm({
          ...form,
          [name]: type == "checkbox" ? checked : value,
          errors: { ...form.errors, [name]: value?.trim() ? "" : "Required" },
        })

        break
      case "real_estate":
        if (name === "pre_country") {
          fetchRegionsList(value)
        } else {
          setForm({
            ...form,
            [name]: value,
          })
        }

        break
      case "finacier":
        setForm({
          ...form,
          [name]: value,
        })
        break
      case "addListOfOwner":
        let latestNumber = 2
        if (form.listOfOwners.length) {
          let numberList = form.listOfOwners.map(each => each.number)

          latestNumber = Math.max(...numberList) + 1
        }
        form.listOfOwners.push({
          number: form.listOfOwners.length ? latestNumber : 1,
          relationsList: relationsList,
        })
        setForm({ ...form, listOfOwners: form.listOfOwners })
        break
      case "ownersList":
        const modfiedListOfOwners = []

        for (let i = 0; i < form.listOfOwners.length; i++) {
          const owner = {
            ...form.listOfOwners[i],
          }

          if (i === key) {
            let regionsList = owner.regionsList || []
            if (name === "pol_country") {
              regionsList = await fetchRegionsList(value)
              owner.pol_region = regionsList[0]?.region || ""
            }

            modfiedListOfOwners.push({
              ...owner,
              [name]: value,
              regionsList,
            })
          } else {
            modfiedListOfOwners.push(owner)
          }
        }

        setForm({
          ...form,
          listOfOwners: modfiedListOfOwners,
        })
        break
      case "removeListOfOwners":
        const modifiedList = form.listOfOwners.filter(
          (each, idx) => idx !== key
        )
        setForm({ ...form, listOfOwners: modifiedList })
        break
      case "addListofBeneficiaries":
        let latestNumbers = 2
        if (form.ListofBeneficiaries.length) {
          let numberList = form.ListofBeneficiaries.map(each => each.number)

          latestNumbers = Math.max(...numberList) + 1
        }
        form.ListofBeneficiaries.push({
          number: form.ListofBeneficiaries.length ? latestNumbers : 1,
          relationsList: relationsList,
        })
        setForm({ ...form, ListofBeneficiaries: form.ListofBeneficiaries })
        break
      case "removeListofBeneficiaries":
        const modifiedLists = form.ListofBeneficiaries.find(
          (each, idx) => idx === key
        )
        handleRemoveBeneficiary(modifiedLists?.pbl_id || 0, key)
        break
      case "addListofDocuments":
        let latestNumberss = 2
        if (form.ListofDocuments.length) {
          let numberList = form.ListofDocuments.map(each => each.number)

          latestNumberss = Math.max(...numberList) + 1
        }
        form.ListofDocuments.push({
          number: form.ListofDocuments.length ? latestNumberss : 1,
        })
        setForm({ ...form, ListofDocuments: form.ListofDocuments })
        break
      case "removeListofDocuments":
        const modifiedListss = form.ListofDocuments.filter(
          (each, idx) => each.pdl_id !== key
        )
        setForm({ ...form, ListofDocuments: modifiedListss })
        handleRemoveDocuments(key)
        break
      case "questions":
        setForm({
          ...form,
          questions: form.questions.map((each, idx) => {
            if (idx == key) {
              if (name == "pq_explain") {
                return {
                  ...each,
                  pq_explain: value,
                }
              }
              return {
                ...each,
                pq_type: value,
              }
            }
            return each
          }),
        })
        break

      case "beneficiariesList":
        setForm({
          ...form,
          ListofBeneficiaries: form.ListofBeneficiaries.map((each, idx) => {
            if (idx === key) {
              return {
                ...each,
                [name]: value,
              }
            }

            return each
          }),
        })
        break
      case "documentList":
        setForm({
          ...form,
          ListofDocuments: form.ListofDocuments.map((each, idx) => {
            if (idx === key) {
              return {
                ...each,
                [name]: value,
              }
            }

            return each
          }),
        })
        break
      default:
        null
    }
  }

  // for image upload
  const handleImage = async (e, action, key) => {
    const { name, files } = e?.target || {}

    switch (action) {
      case "ownersList":
        const result = await FileUpload(files)

        setForm({
          ...form,
          listOfOwners: form.listOfOwners.map((each, idx) => {
            if (idx === key) {
              return {
                ...each,
                [name]: result.files[0]?.url,
              }
            }

            return each
          }),
        })
        break
      case "documentList":
        {
          const result = await FileUpload(files)

          const urlsList = result.files.map(each => each.url)

          setForm({
            ...form,
            ListofDocuments: form.ListofDocuments.map((each, idx) => {
              if (idx === key) {
                return {
                  ...each,
                  [name]: urlsList.join(","),
                }
              }

              return each
            }),
          })
        }
        break
      case "images":
        // for (let i = 0; i < files.length; i++) {
        //   const reader = new FileReader()
        //   reader.readAsDataURL(files[i])
        //   reader.onload = () => {
        //     form.imagesList.push({ pfl_name: reader.result })
        //   }
        // }

        const fileurls = await FileUpload(files)
        const urlsList = fileurls.files.map(each => {
          return { pfl_name: each.url }
        })
        const list = form.imagesList.concat(urlsList)
        console.log("files")
        form.imagesList = list
        setForm({
          ...form,
        })
        break
      default:
        null
    }
  }

  const handleFormSubmit = async () => {
    const isValid = true

    if (!isValid) {
      showMessage("Please fill in the required fields", "error", true)
      return;
    }
    switch (activeTab) {
      case 1:
        try {
          const url = id
            ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_asset_information`
            : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/asset_informations`

          let proShared = []

          let haserrors = false
          if (!form.pro_asset_name) {
            setErrors(prev => {
              return {
                ...prev,
                pro_asset_name: "Please enter name of the asset",
              }
            })
          }

          if (!form.pro_property_type) {
            haserrors = true
            setErrors(prev => {
              return {
                ...prev,
                pro_property_type: "Please select property type",
              }
            })
          }

          if (!form.pro_asset_value) {
            haserrors = true
            setErrors(prev => {
              return {
                ...prev,
                pro_asset_value: "Please enter asset value",
              }
            })
          }

          if (!form.pro_asset_value_currency) {
            haserrors = true
            setErrors(prev => {
              return {
                ...prev,
                pro_asset_value_currency: "Please select a currency",
              }
            })
          }

          if (!form.pro_county_asset) {
            haserrors = true
            setErrors(prev => {
              return {
                ...prev,
                pro_county_asset: "Please select the country of asset",
              }
            })
          }

          if (haserrors) {
            return
          }

          if (form.Family) {
            proShared.push("Family")
          }

          if (form.Friends) {
            proShared.push("Friends")
          }

          if (form.Others) {
            proShared.push("Others")
          }

          if (form.Relative) {
            proShared.push("Relative")
          }

          const data = {
            pro_id: id || assetId,
            pro_uid: userId,
            pro_asset_name: form.pro_asset_name,
            pro_purchase_date: form.pro_purchase_date,
            pro_property_type: form.pro_property_type,
            pro_shared: proShared.join(","),
            pro_asset_value: form.pro_asset_value,
            pro_asset_value_currency: form.pro_asset_value_currency,
            pro_upgraded_value: form.pro_upgraded_value,
            pro_upgraded_value_currency: form.pro_upgraded_value_currency,
            pro_present_value: form.pro_present_value,
            pro_present_value_currency: form.pro_present_value_currency,
            pro_county_asset: form.pro_county_asset,
            pro_number_of_owners: form.pro_number_of_owners,
            pro_date: form.pro_date,
            user_id: userId,
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          setApiStatus({ ...apiStatus, inProgress: true })

          const response = await fetch(url, options, {})

          const addOrUpdateAssetInformation = await response.json()

          if (addOrUpdateAssetInformation?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            setactiveTab(2)
            localStorage.setItem("tab", 2)
            localStorage.setItem("assetId", addOrUpdateAssetInformation.assetId)
            showMessage(addOrUpdateAssetInformation.message, "success")
          } else {
            showMessage(addOrUpdateAssetInformation?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      case 2:
        // /realestate-insurance-list
        if (id || assetId) {
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/realestate-insurance-list`

            let haserrors = false

            if (!form.pre_type_of_real_eatate) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  pre_type_of_real_eatate: "Please Select Type of Real Estate",
                }
              })
            }

            if (haserrors) {
              return
            }

            const data = {
              pro_uid: userId,
              pro_id: id || assetId,
              pre_city: form.pre_city,
              pre_country: form.pre_country,
              pre_number_of_yads: form.pre_number_of_yads,
              pre_region: form.pre_region,
              pre_registrar: form.pre_registrar,
              pre_str1: form.pre_str1,
              pre_str2: form.pre_str2,
              pre_type_of_real_eatate: form.pre_type_of_real_eatate,
              pre_zip: form.pre_zip,
            }

            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }

            const response = await fetch(url, options, {})

            const updateRealEstate = await response.json()

            if (updateRealEstate?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(3)
              localStorage.setItem("tab", 3)
              showMessage(updateRealEstate.message, "success")
            } else {
              showMessage(updateRealEstate?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
        }
        break
      case 3:
        if (id || assetId) {
          // update_financier_info
          try {
            setApiStatus({ ...apiStatus, inProgress: true })
            const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_financier_info`

            let haserrors = false

            if (!form.prof_financier_name) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  prof_financier_name: "Please Enter Financier Name",
                }
              })
            }

            if (!form.prof_address) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  prof_address: "Please Enter Financier Address",
                }
              })
            }

            if (!prof_no_of_year_loan) {
              haserrors = true
              setErrors(prev => {
                return {
                  ...prev,
                  prof_no_of_year_loan: "Please enter No. of Years Loan",
                }
              })
            }

            if (haserrors) {
              return
            }

            const data = {
              prof_uid: userId,
              pro_id: id || assetId,
              prof_financier_name: form.prof_financier_name,
              prof_loan_num: form.prof_loan_num,
              prof_loan_date: form.prof_loan_date,
              prof_address: form.prof_address,
              prof_loan_amount: form.prof_loan_amount,
              prof_no_of_year_loan: form.prof_no_of_year_loan,
              prof_apr: form.prof_apr,
              prof_financier_phone: form.prof_financier_phone,
              prof_financier_website: form.prof_financier_website,
            }

            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }

            const response = await fetch(url, options, {})

            const updateRealEstate = await response.json()

            if (updateRealEstate?.success === true) {
              setApiStatus({ ...apiStatus, inProgress: false })
              setactiveTab(4)
              localStorage.setItem("tab", 4)
              showMessage(updateRealEstate.message, "success")
            } else {
              showMessage(updateRealEstate?.message, "error", false)
              setApiStatus({ ...apiStatus, inProgress: false })
            }
          } catch (error) {}
        }
        break
      case 4:
        // add list of owners
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_owners_info`

          const data = {
            pol_uid: userId,
            pro_id: id || assetId,
            listOfOwners: form.listOfOwners,
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const updateOwnersList = await response.json()

          if (updateOwnersList?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            setactiveTab(5)
            localStorage.setItem("tab", 5)
            await fetchBeneficiaryData()
            showMessage(updateOwnersList.message, "success")
          } else {
            showMessage(updateOwnersList?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      case 5:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_owners`

          const data = {
            pro_id: id || assetId,
            pbl_uid: userId,
            listOfBeneficiaries: form.ListofBeneficiaries.map(
              eachBeneficiary => {
                return {
                  pbl_id: eachBeneficiary.pbl_id || 0,
                  pbl_name: eachBeneficiary.pbl_name || "",
                  pbl_relitionship: eachBeneficiary.pbl_relitionship || "",
                  pbl_persant: eachBeneficiary.pbl_persant || "",
                }
              }
            ),
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateListOfBeneficiaries = await response.json()

          if (addOrUpdateListOfBeneficiaries?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            await fetchListOfDocuments()
            showMessage(addOrUpdateListOfBeneficiaries.message, "success")
            // setTimeout(() => {
            //   history("/asset-wallet")
            // }, 3000)
            setactiveTab(activeTab + 1)
            localStorage.setItem("tab", activeTab + 1)
          } else {
            showMessage(addOrUpdateListOfBeneficiaries?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      case 6:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_or_update_list_of_documents`

          const data = {
            pro_id: id || assetId,
            pdl_uid: userId,
            listofDocuments: form.ListofDocuments.map(eachDocument => {
              return {
                pdl_id: eachDocument.pdl_id || 0,
                pdl_document_name: eachDocument.pdl_document_name || "",
                pdl_tyep_document: eachDocument.pdl_tyep_document || "",
                pdl_image: eachDocument.pdl_image || "",
              }
            }),
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const addOrUpdateListofDocuments = await response.json()

          if (addOrUpdateListofDocuments?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            showMessage(addOrUpdateListofDocuments.message, "success")
            // setTimeout(() => {
            //   history("/asset-wallet")
            // }, 3000)
            setactiveTab(activeTab + 1)
            localStorage.setItem("tab", activeTab + 1)
          } else {
            showMessage(addOrUpdateListofDocuments?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      case 7:
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/udpate_questions_list`

          const data = {
            questions_list: form.questions,
            imagesList: form.imagesList.map(each => {
              return { ...each, pro_id: id || assetId, pfl_uid: userId }
            }),
          }

          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }

          const response = await fetch(url, options, {})

          const questionList = await response.json()

          if (questionList?.success === true) {
            setApiStatus({ ...apiStatus, inProgress: false })
            showMessage(questionList.message, "success")
            setTimeout(() => {
              history("/asset-wallet")
            }, 3000)
          } else {
            showMessage(questionList?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (error) {}
        break
      default:
        break
    }

    if (!(id || assetId) && activeTab != 1) {
      setactiveTab(1)
      localStorage.setItem("tab", 1)
    }
  }

  const handleBlur = e => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value?.trim(),
      errors: { ...form.errors, [name]: value?.trim() ? "" : "Requied" },
    })
  }
  useEffect(() => {
    if (changed) {
      setErrors(prev => {
        const copy = JSON.parse(JSON.stringify(prev))
        delete copy[changed]
        return copy
      })
    }
  }, [changed]) // changed

  return (
    <React.Fragment>
      <AlertMessage />
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">ASSET WALLET</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Services</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/asset-wallet">Asset Wallet</Link>
              </li>
            </ol>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              if (id) {
                                setactiveTab(1)
                              } else {
                                activeTab > 1 && setactiveTab(1)
                              }
                              //activeTab > 1 && setactiveTab(1)
                              localStorage.setItem("tab", 1)
                            }}
                          >
                            <span className="number">1.</span> Asset Information
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              if (id) {
                                setactiveTab(2)
                              } else {
                                activeTab > 2 && setactiveTab(2)
                              }

                              localStorage.setItem("tab", 2)
                            }}
                          >
                            <span className="number">2.</span> Real Estate
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              if (id) {
                                setactiveTab(3)
                              } else {
                                activeTab > 3 && setactiveTab(3)
                              }

                              localStorage.setItem("tab", 3)
                            }}
                          >
                            <span className="number">3.</span>
                            Financier Information
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              if (id) {
                                setactiveTab(4)
                              } else {
                                activeTab > 4 && setactiveTab(4)
                              }

                              localStorage.setItem("tab", 4)
                            }}
                          >
                            <span className="number">4.</span>
                            List of Owners
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 5 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 5 })}
                            onClick={async () => {
                              if (id) {
                                setactiveTab(5)
                              } else {
                                activeTab > 5 && setactiveTab(5)
                              }

                              localStorage.setItem("tab", 5)
                              await fetchBeneficiaryData()
                            }}
                          >
                            <span className="number">5.</span>
                            List of Beneficiaries
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({ current: activeTab === 6 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 6 })}
                            onClick={async () => {
                              if (id) {
                                setactiveTab(6)
                              } else {
                                activeTab > 6 && setactiveTab(6)
                              }

                              localStorage.setItem("tab", 6)
                              await fetchListOfDocuments()
                            }}
                          >
                            <span className="number">6.</span>
                            List of Documents
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({ current: activeTab === 7 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 7 })}
                            onClick={() => {
                              if (id) {
                                setactiveTab(7)
                              } else {
                                activeTab > 7 && setactiveTab(7)
                              }

                              localStorage.setItem("tab", 7)
                            }}
                          >
                            <span className="number">7.</span>
                            Questionnaire
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        {/**4,5,6 dynamic forms and tables */}
                        <TabPane tabId={1}>
                          <Form>
                            <h4 className="page-title-box">
                              Asset Information
                            </h4>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pro_asset_name"
                                    className="form-label"
                                  >
                                    Name of Asset{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.pro_asset_name
                                        ? "invalid-inputfield form-control"
                                        : "pro_asset_name form-control"
                                    }
                                    name="pro_asset_name"
                                    id="pro_asset_name_ljsd"
                                    placeholder="Name of Asset"
                                    required
                                    onChange={e => {
                                      setChanged("pro_asset_name")
                                      handleForm(e, "assetInformation")
                                    }}
                                    value={form.pro_asset_name}
                                    onBlur={handleBlur}
                                  />
                                  {errors.pro_asset_name && (
                                    <div className="invalid-input">
                                      {errors.pro_asset_name}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pro_purchase_date"
                                    className="form-label"
                                  >
                                    Date of Purchase
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="pro_purchase_date"
                                    id="pro_purchase_date"
                                    placeholder="Date of purchase"
                                    value={form.pro_purchase_date}
                                    onChange={e =>
                                      handleForm(e, "assetInformation")
                                    }
                                    max={new Date().toISOString().split("T")[0]}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pro_property_type"
                                    className="form-label"
                                  >
                                    Type of Property{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="pro_property_type"
                                    name="pro_property_type"
                                    required
                                    onChange={e => {
                                      setChanged("pro_property_type")
                                      handleForm(e, "assetInformation")
                                    }}
                                    value={form.pro_property_type}
                                  >
                                    <option value="" selected>
                                      Select Type of Property
                                    </option>
                                    <option value="Cash">Cash</option>
                                    <option value="Furniture">Furniture</option>
                                    <option value="Jewelry">Jewelry</option>
                                    <option value="Lend">Lend</option>
                                    <option value="Loan">Loan</option>
                                    <option value="Real Estate">
                                      Real Estate
                                    </option>
                                    <option value="Single Family">
                                      Single Family
                                    </option>
                                    <option value="Tangible">Tangible</option>
                                  </select>
                                  {errors.pro_property_type && (
                                    <div className="invalid-input">
                                      {errors.pro_property_type}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip02"
                                    className="form-label"
                                  >
                                    Shared by &nbsp;&nbsp;
                                  </label>
                                  <div className="form-check form-check-inline">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="Family"
                                      id="Family"
                                      value="Family"
                                      checked={form.Family}
                                      onChange={e =>
                                        handleForm(e, "assetInformation")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customRadioInline1"
                                    >
                                      Family
                                    </label>
                                  </div>

                                  <div className="form-check form-check-inline">
                                    <input
                                      type="checkbox"
                                      name="Friends"
                                      id="Friends"
                                      value="Friends"
                                      checked={form.Friends}
                                      className="form-check-input"
                                      onChange={e =>
                                        handleForm(e, "assetInformation")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customRadioInline2"
                                    >
                                      Friends
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      type="checkbox"
                                      name="Relative"
                                      id="Relative"
                                      value="Relative"
                                      checked={form.Relative}
                                      className="form-check-input"
                                      onChange={e =>
                                        handleForm(e, "assetInformation")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customRadioInline2"
                                    >
                                      Relative
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      type="checkbox"
                                      name="Others"
                                      id="Others"
                                      value="Others"
                                      checked={form.Others}
                                      className="form-check-input"
                                      onChange={e =>
                                        handleForm(e, "assetInformation")
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customRadioInline2"
                                    >
                                      Others
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip02"
                                    className="form-label"
                                  >
                                    Original Asset Value{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="pro_asset_value"
                                    id="validationTooltip02"
                                    placeholder="Original Asset Value"
                                    required
                                    onChange={e => {
                                      setChanged("pro_asset_value")
                                      handleForm(e, "assetInformation")
                                    }}
                                    value={form.pro_asset_value}
                                  />
                                  {errors.pro_asset_value && (
                                    <div className="invalid-input">
                                      {errors.pro_asset_value}
                                    </div>
                                  )}
                                  <span className="help-inline col-xs-12 col-sm-7"></span>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip03"
                                    className="form-label"
                                  >
                                    Currency{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="validationTooltip01"
                                    name="pro_asset_value_currency"
                                    required
                                    onChange={e => {
                                      setChanged("pro_asset_value_currency")
                                      handleForm(e, "assetInformation")
                                    }}
                                    value={form.pro_asset_value_currency}
                                  >
                                    <option value="">Select Currency</option>
                                    <option value="INR">INR</option>
                                    <option value="USD">USD</option>
                                    <option value="EURO">EURO</option>
                                  </select>
                                  {errors.pro_asset_value_currency && (
                                    <div className="invalid-input">
                                      {errors.pro_asset_value_currency}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip02"
                                    className="form-label"
                                  >
                                    Upgraded Asset Value
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="pro_upgraded_value"
                                    id="validationTooltip02"
                                    placeholder="Upgraded Asset"
                                    onChange={e =>
                                      handleForm(e, "assetInformation")
                                    }
                                    value={form.pro_upgraded_value}
                                  />
                                  <span className="help-inline col-xs-12 col-sm-7"></span>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip03"
                                    className="form-label"
                                  >
                                    Currency
                                  </label>
                                  <select
                                    className="form-select"
                                    id="validationTooltip01"
                                    name="pro_upgraded_value_currency"
                                    onChange={e =>
                                      handleForm(e, "assetInformation")
                                    }
                                    value={form.pro_upgraded_value_currency}
                                  >
                                    <option value="">Select Currency</option>
                                    <option value="INR">INR</option>
                                    <option value="USD">USD</option>
                                    <option value="EURO">EURO</option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip02"
                                    className="form-label"
                                  >
                                    Present Asset Value
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="pro_present_value"
                                    id="validationTooltip02"
                                    placeholder="Present Asset Value"
                                    onChange={e =>
                                      handleForm(e, "assetInformation")
                                    }
                                    value={form.pro_present_value}
                                  />
                                  <span className="help-inline col-xs-12 col-sm-7"></span>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip03"
                                    className="form-label"
                                  >
                                    Currency
                                  </label>
                                  <select
                                    className="form-select"
                                    id="validationTooltip01"
                                    name="pro_present_value_currency"
                                    onChange={e =>
                                      handleForm(e, "assetInformation")
                                    }
                                    value={form.pro_present_value_currency}
                                  >
                                    <option value="">Select Currency</option>
                                    <option value="INR">INR</option>
                                    <option value="USD">USD</option>
                                    <option value="EURO">EURO</option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="validationTooltip01"
                                    className="form-label"
                                  >
                                    Country of Asset{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="validationTooltip01"
                                    name="pro_county_asset"
                                    required
                                    onChange={e => {
                                      setChanged("pro_county_asset")
                                      handleForm(e, "assetInformation")
                                    }}
                                    value={form.pro_county_asset}
                                  >
                                    <option value="" selected>
                                      Select Country of Asset
                                    </option>
                                    <option value="INDIA">INDIA</option>
                                    <option value="USA">USA</option>
                                  </select>
                                  {errors.pro_county_asset && (
                                    <div className="invalid-input">
                                      {errors.pro_county_asset}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="pro_number_of_owners"
                                    className="form-label"
                                  >
                                    Number of Owners{" "}
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="pro_number_of_owners"
                                    id="pro_number_of_owners"
                                    placeholder="Number of Owners"
                                    onChange={e =>
                                      handleForm(e, "assetInformation")
                                    }
                                    value={form.pro_number_of_owners}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                  <label
                                    htmlFor="Created_By"
                                    className="form-label"
                                  >
                                    Created By{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name=""
                                    id="Created_By"
                                    value={`${loginUserData?.user_fname} ${loginUserData?.user_lname}`}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <Form>
                            <h4 className="page-title-box">Real Estate</h4>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="pre_type_of_real_eatate"
                                    className="form-label"
                                  >
                                    Type of Real Estate
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="form-select"
                                    id="pre_type_of_real_eatate"
                                    name="pre_type_of_real_eatate"
                                    required
                                    onChange={e => {
                                      setChanged("pre_type_of_real_eatate")
                                      handleForm(e, "real_estate")
                                    }}
                                    value={form.pre_type_of_real_eatate}
                                  >
                                    <option value="" selected>
                                      Select Type of Real Estate
                                    </option>
                                    <option value="Apartment">Apartment</option>
                                    <option value="Commercial Property">
                                      Commercial Property
                                    </option>
                                    <option value="Condos">Condos</option>
                                    <option value="Container">Container</option>
                                    <option value="Corporeal Property">
                                      Corporeal Property
                                    </option>
                                    <option value="Immovable Property">
                                      Immovable Property
                                    </option>
                                    <option value="Land">Land</option>
                                    <option value="Multifamily">
                                      Multifamily
                                    </option>
                                    <option value="Personal Property">
                                      Personal Property
                                    </option>
                                    <option value="Residential">
                                      Residential
                                    </option>
                                    <option value="Retail">Retail</option>
                                    <option value="Secondary Suite">
                                      Secondary Suite
                                    </option>
                                    <option value="Single Family">
                                      Single Family
                                    </option>
                                  </select>
                                  {errors.pre_type_of_real_eatate && (
                                    <div className="invalid-input">
                                      {errors.pre_type_of_real_eatate}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="pre_number_of_yads"
                                    className="form-label"
                                  >
                                    Number of Sq Feet/Yards
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="pre_number_of_yads"
                                    id="pre_number_of_yads"
                                    placeholder="Number of Sq Feet/Yards"
                                    onChange={e => handleForm(e, "real_estate")}
                                    value={form.pre_number_of_yads}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="pre_registrar"
                                    className="form-label"
                                  >
                                    Registrar
                                  </label>
                                  <textarea
                                    id="pre_registrar"
                                    name="pre_registrar"
                                    rows="3"
                                    className="form-control"
                                    placeholder="Registrar"
                                    onChange={e => handleForm(e, "real_estate")}
                                    value={form.pre_registrar}
                                  ></textarea>
                                </div>
                              </div>

                              <h4 className="card-title">Address</h4>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="validationCustom05"
                                      className="form-label"
                                    >
                                      Street 1
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="pre_str1"
                                      id="validationCustom05"
                                      placeholder="Street 1"
                                      value={form.pre_str1}
                                      onChange={e =>
                                        handleForm(e, "real_estate")
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="validationCustom06"
                                      className="form-label"
                                    >
                                      Street 2
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="pre_str2"
                                      id="validationCustom06"
                                      placeholder="Street 2"
                                      value={form.pre_str2}
                                      onChange={e =>
                                        handleForm(e, "real_estate")
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="validationCustom07"
                                      className="form-label"
                                    >
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="pre_city"
                                      id="validationCustom07"
                                      placeholder="City"
                                      value={form.pre_city}
                                      onChange={e =>
                                        handleForm(e, "real_estate")
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="country"
                                      className="form-label"
                                    >
                                      Country
                                    </label>
                                    <select
                                      className="form-select"
                                      name="pre_country"
                                      id="country"
                                      value={form.pre_country}
                                      onChange={e =>
                                        handleForm(e, "real_estate")
                                      }
                                    >
                                      <option value="">Select Country</option>
                                      <option value="USA" selected="">
                                        USA
                                      </option>
                                      <option value="INDIA">INDIA</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="region"
                                      className="form-label"
                                    >
                                      Region
                                    </label>
                                    <select
                                      className="form-select"
                                      name="pre_region"
                                      id="region"
                                      value={form.pre_region}
                                      onChange={e =>
                                        handleForm(e, "real_estate")
                                      }
                                    >
                                      {form.realEstateRegionList.length ===
                                      0 ? (
                                        <option value="" selected="">
                                          Select Region
                                        </option>
                                      ) : (
                                        <>
                                          <option value="" defaultValue>
                                            Select Region
                                          </option>
                                          {form.realEstateRegionList.map(
                                            each => (
                                              <option
                                                value={each.region}
                                                key={each.region}
                                              >
                                                {each.region}
                                              </option>
                                            )
                                          )}
                                        </>
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="validationCustom07"
                                      className="form-label"
                                    >
                                      Zip
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="pre_zip"
                                      oninput="validateZIP()"
                                      id="zipCode"
                                      placeholder="Zip"
                                      value={form.pre_zip}
                                      onChange={e =>
                                        handleForm(e, "real_estate")
                                      }
                                    />
                                    <div id="zipError"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Form>
                            <h4 className="page-title-box">
                              Financier Information
                            </h4>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="prof_financier_name"
                                    className="form-label"
                                  >
                                    Financier Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="prof_financier_name"
                                    id="prof_financier_name"
                                    placeholder="Financier Name"
                                    required
                                    onChange={e => {
                                      setChanged("prof_financier_name")
                                      handleForm(e, "finacier")
                                    }}
                                    value={form.prof_financier_name}
                                  />
                                  {errors.prof_financier_name && (
                                    <div className="invalid-input">
                                      {errors.prof_financier_name}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="prof_loan_num"
                                    className="form-label"
                                  >
                                    Loan Number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="prof_loan_num"
                                    id="prof_loan_num"
                                    placeholder="Loan Number"
                                    onChange={e => handleForm(e, "finacier")}
                                    value={form.prof_loan_num}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="prof_loan_date"
                                    className="form-label"
                                  >
                                    Loan Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="prof_loan_date"
                                    id="prof_loan_date"
                                    onChange={e => handleForm(e, "finacier")}
                                    value={form.prof_loan_date}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="prof_address"
                                    className="form-label"
                                  >
                                    Financier Address{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <textarea
                                    id="prof_address"
                                    name="prof_address"
                                    rows="3"
                                    className="form-control"
                                    placeholder="Financier Address"
                                    required
                                    onChange={e => {
                                      setChanged("prof_address")
                                      handleForm(e, "finacier")
                                    }}
                                    value={form.prof_address}
                                  ></textarea>
                                  {errors.prof_address && (
                                    <div className="invalid-input">
                                      {errors.prof_address}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="prof_loan_amount"
                                    className="form-label"
                                  >
                                    Loan Amount
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="prof_loan_amount"
                                    id="prof_loan_amount"
                                    placeholder="Loan Amount"
                                    onChange={e => handleForm(e, "finacier")}
                                    value={form.prof_loan_amount}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="prof_no_of_year_loan"
                                    className="form-label"
                                  >
                                    No. of Years Loan{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="prof_no_of_year_loan"
                                    id="prof_no_of_year_loan"
                                    placeholder="No. of Years Loan"
                                    required
                                    onChange={e => {
                                      setChanged("prof_no_of_year_loan")
                                      handleForm(e, "finacier")
                                    }}
                                    value={form.prof_no_of_year_loan}
                                  />
                                  {errors.prof_no_of_year_loan && (
                                    <div className="invalid-input">
                                      {errors.prof_no_of_year_loan}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="prof_apr"
                                    className="form-label"
                                  >
                                    APR (%)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="prof_apr"
                                    id="prof_apr"
                                    placeholder="APR (%)"
                                    onChange={e => handleForm(e, "finacier")}
                                    value={form.prof_apr}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="prof_financier_phone"
                                    className="form-label"
                                  >
                                    Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="prof_financier_phone"
                                    id="prof_financier_phone"
                                    placeholder="Phone Number"
                                    onChange={e => handleForm(e, "finacier")}
                                    value={form.prof_financier_phone}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label
                                    htmlFor="prof_financier_website"
                                    className="form-label"
                                  >
                                    Website
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="prof_financier_website"
                                    id="prof_financier_website"
                                    placeholder="Website"
                                    onChange={e => handleForm(e, "finacier")}
                                    value={form.prof_financier_website}
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={4}>
                          <Suspense
                            fallback={
                              <div className="text-center m-4">Loading...</div>
                            }
                          >
                            <h4 className="page-title-box">List Of Owners</h4>

                            <ListOfOwners
                              form={form}
                              handleForm={handleForm}
                              handleImage={handleImage}
                              proId={id || assetId}
                              activeTab={activeTab}
                            />
                          </Suspense>
                        </TabPane>

                        <TabPane tabId={5}>
                          <Suspense
                            fallback={
                              <div className="text-center m-4">Loading...</div>
                            }
                          >
                            <h4 className="page-title-box">
                              List Of Beneficiaries
                            </h4>

                            <ListofBeneficiaries
                              form={form}
                              handleForm={handleForm}
                              handleImage={handleImage}
                              proId={id || assetId}
                              activeTab={activeTab}
                            />
                          </Suspense>
                        </TabPane>

                        <TabPane tabId={6}>
                          <Suspense
                            fallback={
                              <div className="text-center m-4">Loading...</div>
                            }
                          >
                            <h4 className="page-title-box">
                              List of Documents
                            </h4>

                            <ListofDocuments
                              form={form}
                              handleForm={handleForm}
                              handleImage={handleImage}
                            />
                          </Suspense>
                        </TabPane>

                        <TabPane tabId={7}>
                          <Form>
                            <h4 className="page-title-box">Questionnaire</h4>

                            {form.questions.map((each, idx) => (
                              <div className="row">
                                <div className="col-md-6">{each.pq_name}</div>
                                <div className="col-md-1">
                                  <input
                                    type="radio"
                                    name={`pq_typ${each.pq_id}`}
                                    id={`Yes${each.pq_id}`}
                                    value="Yes"
                                    checked={each.pq_type == "Yes"}
                                    className="form-check-input"
                                    onChange={e =>
                                      handleForm(e, "questions", idx)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customRadioInline2"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="col-md-1">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name={`pq_typ${each.pq_id}`}
                                    id={`No${each.pq_id}`}
                                    value="No"
                                    checked={each.pq_type == "No"}
                                    onChange={e =>
                                      handleForm(e, "questions", idx)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customRadioInline1"
                                  >
                                    No
                                  </label>
                                </div>

                                <div className="col-md-4">
                                  <div className="mb-3 position-relative">
                                    <label
                                      for="validationTooltip41"
                                      className="form-label"
                                    >
                                      Explain
                                    </label>
                                    <textarea
                                      className="form-control"
                                      rows="3"
                                      for="validationTooltip41"
                                      name="pq_explain"
                                      value={each.pq_explain}
                                      onChange={e =>
                                        handleForm(e, "questions", idx)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-title">
                                  Upload Multiple Images
                                </h4>
                                <div className="row">
                                  <div className="col-md-4">
                                    <input
                                      type="file"
                                      name="upload_images"
                                      accept="image/*"
                                      className="upload"
                                      id="asset_images"
                                      multiple
                                      // style={{ display: "none" }}
                                      onChange={e => handleImage(e, "images")}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="card">
                                <AssetImageTable
                                  form={form}
                                  handleForm={handleForm}
                                  proId={id || assetId}
                                  activeTab={activeTab}
                                />
                              </div>
                            </div>
                          </Form>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Button
                            type="button"
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                            onClick={() => {
                              // activeTab != handleFormSubmit()
                              toggleTab(activeTab - 1)
                            }}
                            disabled={activeTab === 1}
                          >
                            Previous
                          </Button>
                        </li>
                        <li
                          className={activeTab === 7 ? "next disabled" : "next"}
                        >
                          <Button
                            disabled={activeTab === 7}
                            type="button"
                            onClick={() => {
                             
                              const isFormValid = handleFormSubmit()

                              if (isFormValid) {
                                toggleTab(activeTab + 1)
                              } else {
                             
                                console.log("Form is invalid")
                              }
                            }}
                          >
                            Next
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      {assetId || id ? "Save" : "Submit"}
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Link
                    to={"/asset-wallet"}
                    className="btn btn-secondary waves-effect"
                    onClick={() => {
                      localStorage.removeItem("assetId")
                      localStorage.removeItem("tab")
                    }}
                  >
                    Cancel
                  </Link>
                  &nbsp; &nbsp;
                  {id ? (
                    <Link
                      to={`/shate_asset-wallet?id=${id || assetId}`}
                      class="btn btn-primary waves-effect waves-light me-1"
                    >
                      Share it with Friend or Family
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddAssetWallet
