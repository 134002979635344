import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback,
  Label,
  Input,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { connect, useDispatch } from "react-redux";

// import { withRouter, Link } from "react-router-dom"
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import logoSm from "../../assets/images/soflogo-min.png";
import Timer from "components/alertmessages/Timer";
import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages";

const VerifyOtp = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [resend, setResend] = useState({ status: false });
  const [seconds, setSeconds] = useState(300);

  useEffect(() => {
    // Check if there's saved seconds in localStorage when component mounts
    const savedSeconds = parseInt(localStorage.getItem("otpSeconds"));
    if (savedSeconds) {
      setSeconds(savedSeconds);
    }

    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timer);
          localStorage.removeItem("otpSeconds"); // Clear saved seconds
          return 0;
        }

        // Save the remaining seconds to localStorage
        localStorage.setItem("otpSeconds", prevSeconds - 1);
        return prevSeconds - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
      // Optionally clear the saved seconds on unmount
      localStorage.removeItem("otpSeconds");
    };
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.number()
        .min(6, "Please enter valid OTP")
        .required("Please Enter Your OTP"),
    }),
    onSubmit: async (values) => {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/verify_otp`;
        const userData = JSON.parse(localStorage.getItem("userData"));
        const data = {
          user_email: userData?.user?.user_email,
          otp: values.otp,
        };
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };

        const response = await fetch(url, options);
        const otpVerify = await response.json();

        if (otpVerify?.success === true) {
          localStorage.setItem("otp_status", 1);
          history("/dashboard");
        } else {
          showMessage(otpVerify.message, "error", false);
          if (otpVerify?.code === 2090) {
            history("/membership_expire");
          }
        }
      } catch (e) {
        showMessage("Otp verification failed", "error", false);
      }
    },
  });

  const handleSetSeconds = () => {
    let reset = true;
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0 && reset !== true) {
          clearInterval(timer);
          localStorage.removeItem("otpSeconds"); // Clear saved seconds
          return 0;
        }

        if (reset) {
          reset = false;
          localStorage.setItem("otpSeconds", 300);
          return 300;
        }
        // Save the remaining seconds to localStorage
        localStorage.setItem("otpSeconds", prevSeconds - 1);
        return parseInt(prevSeconds) - 1;
      });
    }, 1000);
  };

  async function handleResendOtp() {
    try {
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/resend_login_otp`;
      const userData = JSON.parse(localStorage.getItem("userData"));
      const data = { user_email: userData?.user?.user_email };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, options);
      const resendData = await response.json();

      if (resendData?.success === true) {
        setResend({ ...resend, status: false });
        handleSetSeconds();
        showMessage(resendData?.message, "success");
      } else {
        showMessage(resendData?.message, "error", false);
      }
    } catch (e) {
      showMessage(e.message, "error", false);
    }
  }

  const handleResend = () => {
    setResend({ ...resend, status: true });
  };

  document.title = "Otp Verify | SOF";
  return (
    <React.Fragment>
      <AlertMessage />
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">OTP Verify</h5>
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">
                  {props.forgetError && props.forgetError ? (
                    <Alert
                      color="danger"
                      style={{ marginTop: "13px" }}
                      className="mt-5"
                    >
                      {props.forgetError}
                    </Alert>
                  ) : null}
                  {props.forgetSuccessMsg ? (
                    <Alert
                      color="success"
                      style={{ marginTop: "13px" }}
                      className="mt-5"
                    >
                      {props.forgetSuccessMsg}
                    </Alert>
                  ) : null}

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="mt-4"
                  >
                    <div className="py-3 text-center">
                      <Timer
                        handleResendOtp={handleResendOtp}
                        seconds={seconds}
                        setSeconds={setSeconds}
                        resendOtp={handleResend}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="useremail">
                        OTP
                      </Label>
                      <Input
                        name="otp"
                        className="form-control"
                        placeholder="Enter OTP"
                        type="otp"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.otp || ""}
                        invalid={
                          validation.touched.otp && validation.errors.otp
                            ? true
                            : false
                        }
                      />
                      {validation.touched.otp && validation.errors.otp ? (
                        <FormFeedback type="invalid">
                          <div>{validation.errors.otp}</div>
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="row mb-0">
                      <div className="col-12 text-end d-flex justify-content-end">
                        {resend.status === true ? (
                          <button
                            className="btn btn-primary w-md waves-effect waves-light mx-2"
                            onClick={handleResendOtp}
                            type="button"
                          >
                            Resend
                          </button>
                        ) : null}
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    Sign In here
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} SOF. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

VerifyOtp.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(VerifyOtp)
);