import AlertMessage, {
    showMessage,
  } from "components/alertmessages/Alertmessages"
  import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
  import React, { useEffect, useState } from "react"
  import { Link, useLocation, useNavigate } from "react-router-dom"
  import { Container, Spinner,
    Button,
    Modal, ModalBody, ModalHeader,

   } from "reactstrap"
  import "../../../../src//styles/errorfield.scss"
  import { FileUpload } from "helpers/file_uploads/upload_handler"
  import { loginUserData, userId } from "helpers/userId"
  
  const initialFormDetails = {
    dv_name: "",
    dv_comment: "",
    dv_type: "",
    dv_sub_type: "",
    dv_file: "",   
  }
  
  const AddDocumentVault = () => {
    const [form, setForm] = useState(initialFormDetails)
    const [apiStatus, setApiStatus] = useState({ inProgress: false })
  
    const [errors, setErrors] = useState({})
  
  console.log("Documenrts FOrm", form)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [isUserValidated, setIsUserValidated] = useState(false)
  const [userKayVal, setUserKayVal] = useState("")
  const [displayImg, setDisplayImg] = useState({})

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }
  const tog_small = () => {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const tog_xlarge = () => {
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }
  const handleUserKay = () => {
    if (userKayVal && userKayVal === loginUserData.user_key) {
      setIsUserValidated(false)
      tog_xlarge()
      tog_small()
    } else {
      setIsUserValidated(true)
    }
    setUserKayVal("")
  }

  const displayModel = (title, url) => {
    tog_small()
    const fileType = /[^.]+$/.exec(url)
    const isImage = ["jpg", "jpeg", "png"].some(ext => {
      return fileType.some(ext2 => {
        return ext === ext2
      })
    })
    
    console.log(url, fileType)
    let path =
      fileType[0] === "doc" || fileType[0] === "docx"
        ? `https://docs.google.com/gview?url=${url}&embedded=true`
        : url
    setDisplayImg({
      path: path,
      title: title,
      isImage: isImage,
      show: true,
      fileType: fileType,
    })
  }
  

    // history for navigation
    const history = useNavigate()
  
    // to get api params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const type = searchParams.get("type")
 
    // -- Fetch Document Vault list start -- //
    const fetchData = async () => {
      try {
        const user_id =
          JSON.parse(localStorage.getItem("userData")).user.user_id || 0
        const response = await fetch(
          `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/single_document_vault?dv_id=${id}`
        )
        const data = await response.json()
  
        if (data?.success) {
          const acData = data?.data[0] 
         
          setForm({
            ...form,
            dv_name: acData.dv_name,
            dv_comment: acData.dv_comment,
            dv_type: acData.dv_type,
            dv_sub_type: acData.dv_sub_type,  
            dv_file : acData.dv_file,  
          })
        }
      } catch (err) {        
          history("/document_vault_list")
      }
    }
  
    useEffect(() => {
      if (id) {
        fetchData()
      }
    }, [])
  
    // -- Fetch Document Vault list end -- //
  
   
  
    const handleForm = e => {
      let { name, value } = e.target
  
      if (name === "user_country") {
       // fetchRegionsList(value)
      } else {
        setForm({ ...form, [name]: value })
      }
    }
  
    // for image upload
    const handleImage = async e => {
      const { name, files } = e.target
      const result = await FileUpload(files)
  
      setForm({ ...form, [name]: result.files?.[0]?.url })
    }
  
    const validateForm = () => {
      var isValid = true
      const newErrors = {}
  
      // Validation logic for each input
      if (!form.dv_name.trim()) {
        newErrors.dv_name = "Docunent Name is required"
        isValid = false
      }    
  
      setErrors(newErrors)
      // console.log(errors);
      // console.log(isValid);
      return isValid
    }
  
    const handleFormSubmit = async () => {
      validateForm()
      if (!!form.dv_name) {
        try {
          setApiStatus({ ...apiStatus, inProgress: true })
          const url = id
            ? `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_document_vault`
            : `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/add_document_valut`
  
          const data = {
            user_id: userId,
            dv_id: id || 0,
            dv_name: form.dv_name,
            dv_comment: form.dv_comment,
            dv_type: form.dv_type,
            dv_sub_type: form.dv_sub_type,
            dv_file: form.dv_file,            
          }          
          
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
  
          const response = await fetch(url, options, {})
  
          const addOrUpdateDocumentVault = await response.json()
  
          if (addOrUpdateDocumentVault?.success === true) {
            showMessage(addOrUpdateDocumentVault.message, "success")
            setTimeout(() => {
              type == 1
                ? history("/document_vault_list")
                : history("/document_vault_list")
            }, 3000)
          } else {
            showMessage(addOrUpdateDocumentVault?.message, "error", false)
            setApiStatus({ ...apiStatus, inProgress: false })
          }
        } catch (e) {
          showMessage(e, "error", false)
          setApiStatus({ ...apiStatus, inProgress: false })
        }
      }
    }
  
    return (
      <React.Fragment>
        <AlertMessage />
        <div className="page-content">
          <Container fluid>
            <div className="page-title-box">
              <h4 className="font-size-18">ADD DOCUMENT VAULT</h4>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/document_vault_list">Services</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/document_vault_list">Document Vault</Link>
                </li>
              </ol>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3 position-relative">
                      <label htmlFor="dv_name" className="form-label">
                      Document Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="dv_name"                        
                        placeholder="Document Name"
                        required
                        onChange={handleForm}
                        value={form.dv_name}
                      />
                      {errors.dv_name && (
                        <div className="invalid-input">{errors.dv_name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-8"></div>

                  <div className="col-md-4">
                    <div className="mb-3 position-relative">
                      <label htmlFor="dv_comment" className="form-label">
                      Comment
                      </label>
                      <textarea
                        id="validationTooltip01"
                        name="dv_comment"
                        rows="3"
                        className="form-control"                        
                        onChange={handleForm}
                        value={form.dv_comment}
                    ></textarea> 
                    </div>
                  </div>
                  <div className="col-md-8"></div>

                  <div className="col-md-4">
                    <div className="mb-3 position-relative">
                      <label htmlFor="dv_type" className="form-label">
                      Document Type 
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="dv_type"                       
                        placeholder="Document Type"
                        required
                        onChange={handleForm}
                        value={form.dv_type}
                      />                     
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3 position-relative">
                      <label htmlFor="dv_sub_type" className="form-label">
                      Document Sub Type 
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="dv_sub_type"                       
                        placeholder="Document Sub Type"
                        required
                        onChange={handleForm}
                        value={form.dv_sub_type}
                      />                      
                    </div>
                  </div>
                  <div className="col-md-4"></div> 
                  <div className="col-md-4">
                    <div className="mb-3 position-relative">
                      <label
                        htmlFor="validationTooltipUsername"
                        className="form-label"
                      >
                       Document
                      </label>
                      <div className="input-group">
                        <input
                          type="file"
                          name="dv_file"
                          accept="image/*,.pdf,.doc,.docx"
                          className="upload"
                          id="ac_img"
                          style={{ display: "none" }}
                          onChange={handleImage}
                        />
                        <label htmlFor="ac_img">
                          <p className="btn btn-info">Upload Document </p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                              {id ? (
                                <Button
                                  color="primary"
                                  onClick={() => displayModel(form.dv_name, form.dv_file)}
                                >
                                  View document
                                </Button>
                              ) : null}


                              <Modal isOpen={modal_small} toggle={tog_small} size="sm">
                                <ModalHeader className="mt-0" toggle={tog_small}>
                                  Please enter "User Key" to open file
                                </ModalHeader>
                                <ModalBody>
                                  <p>
                                    Note: Please find your "User Key" in personal
                                    information section
                                  </p>
                                  <p>
                                    <input
                                      type="userKay"
                                      className="form-control"
                                      name="userKay"
                                      id="userKay"
                                      value={userKayVal}
                                      onChange={e => setUserKayVal(e.target.value)}
                                      onBlur={e => setIsUserValidated(false)}
                                    />
                                  </p>
                                  <p>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={handleUserKay}
                                    >
                                      Verify User Key
                                    </button>
                                  </p>
                                  {isUserValidated && (
                                    <p className="alert alert-danger ">
                                      Please enter valid "User Key" value.
                                    </p>
                                  )}
                                </ModalBody>
                              </Modal>
                              {displayImg.show && (
                                <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
                                  <ModalHeader className="mt-0" toggle={tog_xlarge}>
                                    {displayImg.title}
                                  </ModalHeader>
                                  <ModalBody>
                                    {console.log(displayImg)}
                                    {displayImg.isImage ? (
                                      <img src={displayImg.path} width="100%"></img>
                                    ) : (
                                      <iFrame
                                        src={displayImg.path}
                                        width="100%"
                                        height="650px"
                                      ></iFrame>
                                    )}
                                  </ModalBody>
                                </Modal>
                              )}
                
              </div>
            </div>
         
  
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {apiStatus.inProgress ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        disabled={true}
                      >
                        <Spinner color="light" size="sm">
                          Loading ...
                        </Spinner>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light me-1"
                        onClick={handleFormSubmit}
                      >
                        {id ? "Save" : "Submit"}
                      </button>
                    )}
                    &nbsp; &nbsp;
                    <Link
                      to={
                        type == 1 ? "/document_vault_list" : "/document_vault_list"
                      }
                      className="btn btn-secondary waves-effect"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default AddDocumentVault
  