import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"

//i18n
import i18n from "../../../i18n"
import {languages, countries } from "common/languages";


const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("")
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    setSelectedLang(currentLanguage)
  }, [])

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    setSelectedLang(lang)
  }

  const country = () => {
    const {country} = JSON.parse(localStorage.getItem("userData")).user;
    return countries[country || "USA"];
  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <div className="d-flex align-items-center ms-2">
        <img
          src={country()?.flag}
          alt="Veltrix"
          height="16"
          className="me-2"
        />
        {" "}{country().label}
      </div>
    </>
  )
}

export default withTranslation()(LanguageDropdown)
