import React from "react"
import { Col, Container, Row } from "reactstrap"

const Verifications = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="font-size-18">VERIFICATIONS</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Account Info</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Verifications</a>
              </li>
            </ol>
          </div>
          <div className="col-lg-10">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Profile Verifications</h4>
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr className="table-light">
                        <td className="">Email</td>
                        <td></td>
                        <td className="hidden-480">
                          <span className="label label-warning">Verified </span>
                        </td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="">Mobile</td>
                        <td></td>
                        <td className="hidden-480">
                          <span className="label label-warning">Not Verified </span>
                        </td>
                        <td>
                          <a
                            href="sms-verify.php"
                            className="btn btn-sm btn-primary"
                          >
                            Verify
                          </a>
                        </td>
                      </tr>
                      <tr className="table-light">
                        <td className="">Aadhaar</td>
                        <td></td>
                        <td className="hidden-480">
                          <span className="label label-warning">Not Verified </span>
                        </td>
                        <td>
                          <a
                            href="aadhar-verify.php"
                            className="btn btn-sm btn-primary"
                          >
                            Verify
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td className="">SSN</td>
                        <td></td>
                        <td className="hidden-480">
                          <span className="label label-warning">Not Verified </span>
                        </td>
                        <td>
                          <a
                            href="ssn-verify.php"
                            className="btn btn-sm btn-primary"
                          >
                            Verify
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Verifications
