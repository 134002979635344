import AlertMessage, {
  showMessage,
} from "components/alertmessages/Alertmessages"
import { userId } from "helpers/userId"
import { decryptData } from "pages/Utility/enrypt_decrypt/crypto.secure"
import React, { memo, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Container, Form, Button, Spinner } from "reactstrap"
import { FileUpload } from "helpers/file_uploads/upload_handler"

const intialForm = {
  relationsList: [],
  regionsList: [],
  pol_id: "",
  pol_fname: "",
  pol_mname: "",
  pol_lname: "",
  pol_email: "",
  pol_phone: "",
  pol_relitionship: "",
  pol_describe: "",
  pol_persant: "",
  pol_image: "",
  pol_street1: "",
  pol_street2: "",
  pol_city: "",
  pol_country: "",
  pol_region: "",
  pol_zip: "",
}

const EditOwner = () => {
  const [form, setForm] = useState(intialForm)
  // history for navigation
  const history = useNavigate()
  const [apiStatus, setApiStatus] = useState({ inProgress: false })

  // to get api params
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get("id")
  const relationsList = JSON.parse(localStorage.getItem("relationsList"))

  async function fetchRelationsList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/relations_list`
      )
      const data = await response.json()

      if (data?.data) {
        localStorage.setItem("relationsList", JSON.stringify(data?.data))
      }

      return data?.data
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchRelationsList()
  }, [])

  const handleForm = async (e, action, key) => {
    let { name, value, type, checked } = e?.target || {}

    if (name === "pol_country") {
      const regionList = (await fetchRegionsList(value)) || []
      setForm({
        ...form,
        [name]: value,
        regionsList: regionList,
        pol_region: regionList[0]?.region || "",
      })
    } else {
      setForm({ ...form, [name]: value })
    }
  }

  const handleFormSubmit = async () => {
    try {
      setApiStatus({ ...apiStatus, inProgress: true })
      const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/update_single_list_of_owners`

      const data = {
        pol_id: form.pol_id,
        pol_fname: form.pol_fname,
        pol_mname: form.pol_mname,
        pol_lname: form.pol_lname,
        pol_email: form.pol_email,
        pol_phone: form.pol_phone,
        pol_relitionship: form.pol_relitionship,
        pol_describe: form.pol_describe,
        pol_persant: form.pol_persant,
        pol_image: form.pol_image,
        pol_street1: form.pol_street1,
        pol_street2: form.pol_street2,
        pol_city: form.pol_city,
        pol_country: form.pol_country,
        pol_region: form.pol_region,
        pol_zip: form.pol_zip,
      }

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }

      const response = await fetch(url, options, {})

      const updateOwnersList = await response.json()

      if (updateOwnersList?.success === true) {
        setApiStatus({ ...apiStatus, inProgress: false })
        showMessage(updateOwnersList.message, "success")

        setTimeout(() => {
          history(-1)
        }, [2000])
      } else {
        showMessage(updateOwnersList?.message, "error", false)
        setApiStatus({ ...apiStatus, inProgress: false })
      }
    } catch (e) {
      showMessage(updateOwnersList?.message, "error", false)
    }
  }
   
   // for image upload
   const handleImage = async e => {
    const { name, files } = e.target
    const result = await FileUpload(files)

    setForm({ ...form, [name]: result.files?.[0]?.url })
  }


  // const handleImage = (e, action, key) => {
  //   const { name, files } = e?.target || {}

  //   for (let i = 0; i < files.length; i++) {
  //     const reader = new FileReader()
  //     reader.readAsDataURL(files[i])
  //     reader.onload = () => {
  //       setForm({
  //         ...form,
  //         [name]: reader.result,
  //       })
  //     }
  //   }
  // }

  async function fetchRegionsList(countryName) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN_URL}api/v1/globals/regions?country=${countryName}`
      )

      const data = await response.json()

      return data?.data || []
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `${process.env.REACT_APP_DOMAIN_URL}api/v1/customer/services/owners_list?pol_id=${id}&pol_uid=${userId}`

        const response = await fetch(url)

        const data = await response.json()

        if (data?.success) {
          const regionsList =
            (await fetchRegionsList(data?.data[0].pol_country)) || []

          const decryptedData = data?.data?.map(each => {
            const pol_fname = decryptData(each.pol_fname)
            return {
              ...each,
              pol_fname: each.pol_fname || "",
              pol_lname: each.pol_lname || "",
              pol_relitionship: each.pol_relitionship || "",
              pol_persant: each.pol_persant || "",
              relationsList: relationsList,
              regionsList: regionsList,
            }
          })

          const resultData = decryptedData[0]

          setForm({ ...form, ...resultData })
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchData()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <AlertMessage />
        <Container fluid>
        <div className="page-title-box">
            <h4 className="font-size-18">EDIT OWNER</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Services</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/">Edit Owner</a>
              </li>
            </ol>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">

                    <Form className="my-4">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3 position-relative">
                            <label htmlFor="pol_fname" className="form-label">
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="pol_fname"
                              id="pol_fname"
                              placeholder="First Name"
                              onChange={e => handleForm(e, "ownersList")}
                              value={form.pol_fname}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 position-relative">
                            <label htmlFor="pol_mname" className="form-label">
                              Middle Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="pol_mname"
                              id="pol_mname"
                              placeholder="Middle Name"
                              value={form.pol_mname}
                              onChange={e => handleForm(e, "ownersList")}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 position-relative">
                            <label htmlFor="pol_lname" className="form-label">
                              Last name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="pol_lname"
                              id="pol_lname"
                              placeholder="Last name"
                              onChange={e => handleForm(e, "ownersList")}
                              value={form.pol_lname}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 position-relative">
                            <label
                              htmlFor="validationTooltipUsername"
                              className="form-label"
                            >
                              Email Address
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="validationTooltipUsernamePrepend"
                                >
                                  @
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                name="pol_email"
                                id="validationTooltipUsername"
                                placeholder="Email Address"
                                aria-describedby="validationTooltipUsernamePrepend"
                                disabled=""
                                onChange={e => handleForm(e, "ownersList")}
                                value={form.pol_email}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 position-relative">
                            <label htmlFor="validationTooltip03" className="form-label">
                              Phone Number
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              name="pol_phone"
                              id="validationTooltip03"
                              placeholder="Phone Number"
                              onChange={e => handleForm(e, "ownersList")}
                              value={form.pol_phone}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 position-relative">
                            <label htmlFor="validationTooltip01" className="form-label">
                              Relationship
                            </label>
                            <select
                              className="form-select"
                              id="validationTooltip01"
                              name="pol_relitionship"
                              onChange={e => handleForm(e, "ownersList")}
                              value={form.pol_relitionship}
                            >
                              <option value="" selected="">
                                Select Relationship
                              </option>
                              {form.relationsList.map(each => (
                                <option key={each.url_id} value={each.url_name}>
                                  {each.url_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 position-relative">
                            <label htmlFor="validationTooltip03" className="form-label">
                              Describe
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              name="pol_describe"
                              id="validationTooltip03"
                              placeholder="Describe"
                              onChange={e => handleForm(e, "ownersList")}
                              value={form.pol_describe}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3 position-relative">
                            <label htmlFor="validationTooltip03" className="form-label">
                              % of Ownership
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              name="pol_persant"
                              id="validationTooltip03"
                              placeholder="% of Ownership"
                              onChange={e => handleForm(e, "ownersList")}
                              value={form.pol_persant}
                            />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <img
                            id="output_image"
                            className="rounded avatar-lg card-img img-thumbnail border border-2 p-0 d-inline-block"
                            alt="Your Image"
                            width="100"
                            height="80"
                            src={form.pol_image || "default-profile.png"}
                          />
                        </div>
                        <div className="col-md-2">
                          <div className="mb-3 position-relative">
                            <label
                              htmlFor="validationTooltipUsername"
                              className="form-label"
                            >
                              User Image
                            </label>
                            <div className="input-group">
                              <input
                                type="file"
                                name="pol_image"
                                accept="image/*"
                                className="upload"
                                id="pol_image"
                                style={{ display: "none" }}
                                onChange={handleImage}
                              />
                              <label htmlFor="pol_image">
                                <p className="btn btn-info">Upload Image </p>
                              </label>
                            </div>
                          </div>
                        </div>
                        <h4 className="card-title">Address</h4>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="validationCustom05" className="form-label">
                              Street 1
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="pol_street1"
                              id="validationCustom05"
                              placeholder="Street 1"
                              value={form.pol_street1}
                              onChange={e => handleForm(e, "ownersList")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="validationCustom06" className="form-label">
                              Street 2
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="pol_street2"
                              id="validationCustom06"
                              placeholder="Street 2"
                              value={form.pol_street2}
                              onChange={e => handleForm(e, "ownersList")}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="validationCustom07" className="form-label">
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="pol_city"
                              id="validationCustom07"
                              placeholder="City"
                              value={form.pol_city}
                              onChange={e => handleForm(e, "ownersList")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="country" className="form-label">
                              Country
                            </label>
                            <select
                              className="form-select"
                              name="pol_country"
                              id="country"
                              value={form.pol_country}
                              onChange={e => handleForm(e, "ownersList")}
                            >
                              <option value="">Select Country</option>
                              <option value="USA" selected="">
                                USA
                              </option>
                              <option value="INDIA">INDIA</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="region" className="form-label">
                              Region
                            </label>
                            <select
                              className="form-select"
                              name="pol_region"
                              id="pol_region"
                              value={form.pol_region}
                              onChange={e => handleForm(e, "ownersList")}
                            >
                              {form.regionsList?.length === 0 ? (
                                <option value="" selected="">
                                  Select Region
                                </option>
                              ) : (
                                <>
                            <option value="" defaultValue>
                              Select Region
                            </option>
                            { form.regionsList?.map(each => (
                                  <option value={each.region} key={each.region}>
                                    {each.region}
                                  </option>
                                ))}
                            </>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label htmlFor="validationCustom07" className="form-label">
                              Zip
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="pol_zip"
                              oninput="validateZIP()"
                              id="zipCode"
                              placeholder="Zip"
                              value={form.pol_zip}
                              onChange={e => handleForm(e, "ownersList")}
                            />
                            <div id="zipError"></div>
                          </div>
                        </div>
                      </div>
                    </Form>
                </div>
              </div>
            </div>           
          </div>

          
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  {apiStatus.inProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      disabled={true}
                    >
                      <Spinner color="light" size="sm">
                        Loading ...
                      </Spinner>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light me-1"
                      onClick={handleFormSubmit}
                    >
                      Save
                    </button>
                  )}
                  &nbsp; &nbsp;
                  <Button
                    type="button"
                    className="btn btn-secondary waves-effect"
                    onClick={() => {
                      history(-1)
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default memo(EditOwner)
